import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Down: FC<IconSvgProps> = ({ color, clss }) => {
    return (
        <svg className={clss} width="22" height="22" viewBox="0 0 22 22" focusable="false" aria-hidden="true">
            <g fill="none" fillRule="evenodd">
                <path
                    fill={color}
                    d="M16.66 1.381l-4.72 1.884v11.02l4.72-1.883V1.382zM6.385 12.29l4.998 1.995V3.265L6.386 1.269v11.02zm-5.274 1.88l4.72-1.882V1.269l-4.72 1.883v11.02zM17.77.558v12.217c0 .225-.14.433-.347.511l-5.553 2.221a.498.498 0 01-.417 0l-5.345-2.134-5.344 2.134a.44.44 0 01-.209.044.576.576 0 01-.312-.096.569.569 0 01-.243-.459V2.779c0-.226.14-.434.347-.512L5.9.045c.14-.06.278-.06.417 0l5.345 2.135L17.006.045a.556.556 0 01.521.053.57.57 0 01.243.46z"
                />
            </g>
        </svg>
    );
};

export default Down;
